'use client'
import useAppErrorStore from '@/app/studio/useAppErrorStore'
import { Button } from '@/components/ui/Button'
import useWhisperTranscription from '@/hooks/useWhisperTranscription'
import { CircleNotch, Microphone, PaperPlaneRight, Stop, Trash, VideoCamera } from '@phosphor-icons/react'
import type { ChatRequestOptions, CreateMessage, Message } from 'ai'
import { memo } from 'react'
import { type ChangeEventHandler, type FormEvent, type KeyboardEvent, useCallback, useEffect, useRef } from 'react'
import ShortUniqueId from 'short-unique-id'
import { twMerge } from 'tailwind-merge'
import VideoStreaming from './video-streaming/VideoStreaming'

export interface ChatInputProps {
  input: string
  setInput: (input: string) => void
  handleInputChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  handleSubmit: (e: FormEvent<HTMLFormElement>, data: Record<string, unknown> | undefined) => void
  isDisabled?: boolean
  replicaName: string
  isReplicaTyping: boolean
  inputPrompt?: string
  stop: () => void
  isNewChat: boolean
  clearChat: () => void
  appendMessage: (
    message: Message | CreateMessage,
    chatRequestOptions?: ChatRequestOptions,
  ) => Promise<string | null | undefined> | null
  replicaSlug: string
  interactiveAvatarId: string | null
  interactiveAvatarEnabled: boolean
  classNameInput?: string
}

export default memo(function ChatInput({
  input,
  handleInputChange,
  handleSubmit,
  isDisabled = false,
  replicaName,
  isReplicaTyping,
  inputPrompt = 'Message',
  stop,
  isNewChat,
  clearChat,
  appendMessage,
  replicaSlug,
  interactiveAvatarId,
  interactiveAvatarEnabled,
  classNameInput = '',
}: ChatInputProps) {
  const { setError } = useAppErrorStore()
  const { isListening, formatDuration, duration, isProcessing, toggleRecording } = useWhisperTranscription({
    setInput: (input: string) => {
      const { randomUUID } = new ShortUniqueId({ length: 10 })
      const uuid = randomUUID()
      appendMessage({
        id: uuid,
        content: input,
        role: 'user',
        createdAt: new Date(),
      })
    },
    isReplicaTyping,
    onError: (message: string, error: Error) => {
      setError({
        friendlyError: message,
        error,
      })
    },
  })

  const videoStreamingRef = useRef<{
    streamHandler: () => void
  }>(null)

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  const handleTextareaInput = () => {
    const currentTextareaRef = textareaRef.current

    if (!currentTextareaRef) return

    const scrollHeight = currentTextareaRef.scrollHeight

    if (scrollHeight > 60) {
      currentTextareaRef.style.overflowY = 'scroll'
      currentTextareaRef.style.height = '60px'
    } else if (scrollHeight > 45) {
      currentTextareaRef.style.overflowY = 'hidden'
      currentTextareaRef.style.height = '45px'
    } else {
      currentTextareaRef.style.overflowY = ''
      currentTextareaRef.style.height = ''
    }
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (sendMessageDisabled) {
      return
    }
    handleSubmit(e, undefined)

    const currentTextAreaRef = textareaRef.current

    if (!currentTextAreaRef) return

    currentTextAreaRef.style.overflowY = ''
    currentTextAreaRef.style.height = ''
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onSubmit(e)
    }
  }

  const focusTextarea = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (!isDisabled && !isReplicaTyping) focusTextarea()
  }, [isDisabled, isReplicaTyping, focusTextarea])

  const hasText = !!input.trim().length

  const sendMessageDisabled = isDisabled || !hasText

  let textareaPlaceholder = ''
  if (isListening) textareaPlaceholder = 'Listening...'
  else if (isProcessing) textareaPlaceholder = 'Processing...'
  else textareaPlaceholder = `${inputPrompt} ${replicaName}...`

  const disableFieldset = isDisabled || isReplicaTyping

  return (
    <div className="flex w-full flex-col gap-1.5">
      {interactiveAvatarId && interactiveAvatarEnabled ? (
        <VideoStreaming
          className="absolute inset-x-0 bottom-8"
          replicaSlug={replicaSlug}
          ref={videoStreamingRef}
          avatarId={interactiveAvatarId}
        />
      ) : null}

      <form onSubmit={onSubmit} onKeyDown={handleKeyDown}>
        <fieldset className="flex flex-row items-center justify-center gap-2">
          {!isNewChat && (
            <Button
              className={twMerge(
                'flex shrink-0 items-center justify-center',
                isReplicaTyping || isDisabled ? 'opacity-40 pointer-events-none' : 'hover:opacity-80',
              )}
              type="button"
              variant="secondary"
              disabled={isReplicaTyping || disableFieldset}
              onClick={clearChat}
            >
              <Trash />
            </Button>
          )}

          <div
            className={twMerge(
              'flex min-h-11 w-full items-center rounded border border-slate-350 bg-white p-2 pl-4',
              classNameInput,
            )}
          >
            <textarea
              ref={textareaRef}
              className={twMerge(
                'h-auto w-full resize-none overflow-y-auto border-0 bg-transparent p-0 text-black placeholder:text-[#969285] focus-within:outline-none focus:ring-0',
                (isDisabled || isReplicaTyping) && 'opacity-40',
              )}
              value={input}
              onChange={handleInputChange}
              onInput={handleTextareaInput}
              placeholder={textareaPlaceholder}
              rows={1}
              disabled={isDisabled || isReplicaTyping || isListening || isProcessing || disableFieldset}
            />

            <div className="flex items-center gap-2">
              {interactiveAvatarId && interactiveAvatarEnabled && (
                <Button
                  className={twMerge(
                    'flex items-center justify-center',
                    (isDisabled || isReplicaTyping) && 'opacity-40',
                  )}
                  onClick={videoStreamingRef.current?.streamHandler}
                  type="button"
                  disabled={disableFieldset}
                  variant="ghost"
                  size="icon"
                >
                  <VideoCamera />
                </Button>
              )}

              <Button
                size={isProcessing || isListening ? 'small' : 'icon'}
                variant="ghost"
                className="flex items-center justify-center"
                onClick={toggleRecording}
                type="button"
                disabled={isDisabled || isReplicaTyping || isProcessing || disableFieldset}
              >
                {!isProcessing &&
                  (isListening ? (
                    <Stop className="flex-shrink-0 text-red-500" />
                  ) : (
                    <Microphone className="flex-shrink-0" />
                  ))}

                {isProcessing && <CircleNotch className="animate-spin" />}

                {isListening && <span className="ml-2 text-sm text-red-500"> {formatDuration(duration)}</span>}
              </Button>

              {!isReplicaTyping && (
                <Button size="icon" type="submit">
                  <div className="flex items-center justify-center w-9 h-9">
                    <PaperPlaneRight weight="fill" />
                  </div>
                </Button>
              )}

              {isReplicaTyping && (
                <Button size="icon" type="button" onClick={stop}>
                  <div className="flex items-center justify-center w-9 h-9">
                    <Stop weight="fill" />
                  </div>
                </Button>
              )}
            </div>
          </div>
        </fieldset>
      </form>
      <div
        className={twMerge(
          'hidden text-xs opacity-0 transition-opacity duration-300 sm:inline',
          hasText && 'opacity-50',
          isNewChat ? 'pl-6' : 'pl-[70px]',
        )}
      >
        Press <span className="font-bold">Enter</span> to send the message. Press{' '}
        <span className="font-bold">Shift + Enter</span> to start a new line.
      </div>
    </div>
  )
})
