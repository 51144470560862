'use client'

import useAppErrorStore from '@/app/studio/useAppErrorStore'
import LoadingState from '@/components/ui/LoadingState'
import { PauseCircle, Play, PlayCircle } from '@phosphor-icons/react'
import { useWavesurfer } from '@wavesurfer/react'
import { useCallback, useEffect, useRef, useState } from 'react'

const AudioBubble = ({
  className,
  replicaUuid,
  content,
  introductionAudioId,
}: {
  className?: string
  replicaUuid: string
  content: string
  introductionAudioId: string | null
}) => {
  const { setError } = useAppErrorStore()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [audioUrl, setAudioUrl] = useState<string>()
  const [loadingTextToSpeech, setLoadingTextToSpeech] = useState<boolean>(false)

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: containerRef,
    height: 44,
    barGap: 2,
    barRadius: 4,
    barWidth: 3,
    barHeight: 1,
    waveColor: '#d1a0b5',
    progressColor: '#a3426c',
    cursorWidth: 0,
    interact: true,
    fillParent: true,
    hideScrollbar: true,
    url: audioUrl,
  })

  const textToSpeech = useCallback(async () => {
    try {
      setLoadingTextToSpeech(true)

      const response = await fetch('/api/text-to-speech', {
        method: 'POST',
        body: JSON.stringify({ replicaUuid, content, saveOnDb: true }),
      })

      const res = await response.json()

      if (res.success) setAudioUrl(res.path)
      else
        setError({
          friendlyError: 'Something went wrong converting text to speech.',
          error: res.error,
        })

      setLoadingTextToSpeech(false)
    } catch (error) {
      setError({
        friendlyError: 'Something went wrong converting text to speech.',
        error,
      })

      setLoadingTextToSpeech(false)
    }
  }, [content])

  const checkIfWelcomeMessageExist = useCallback(async (url: string) => {
    let response: Response
    try {
      response = await fetch(url)
      console.log(response)
      return response.ok
    } catch (e) {
      console.log("Welcome message doesn't exists", e)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!introductionAudioId) return

      const url = `${process.env.NEXT_PUBLIC_STORAGE_PATH}/welcome_messages/${replicaUuid}/${introductionAudioId}`
      const res = await checkIfWelcomeMessageExist(url)

      if (res) setAudioUrl(url)
      else textToSpeech()
    })()
  }, [introductionAudioId, textToSpeech, checkIfWelcomeMessageExist])

  const playPause = useCallback(() => {
    if (!wavesurfer) return

    if (isPlaying) wavesurfer.pause()
    else wavesurfer.play()
  }, [isPlaying, wavesurfer])

  const changePlaybackSpeed = (speedMultiplier: number) => {
    if (!wavesurfer) return

    wavesurfer.setPlaybackRate(speedMultiplier)
  }

  useEffect(() => {
    if (!containerRef.current) return
    if (!wavesurfer) return

    wavesurfer.on('interaction', () => {
      wavesurfer.play()
    })
    wavesurfer.on('finish', () => {
      wavesurfer.stop()
    })

    return () => {
      wavesurfer.destroy()
    }
  }, [wavesurfer])

  return (
    <div className={className}>
      {loadingTextToSpeech && (
        <LoadingState text="Loading Voice..." className="ml-auto bg-background px-3 py-1 text-sm text-primary " />
      )}

      {!audioUrl && !loadingTextToSpeech && (
        <button
          onClick={textToSpeech}
          className="ml-auto flex w-fit items-center py-1 pr-5 text-sm text-primary duration-150 active:scale-90"
          type="button"
        >
          <Play size={14} weight="bold" className="mr-2" />
          Play Message
        </button>
      )}

      {audioUrl && (
        <div className="mt-3 flex flex-row items-center gap-1 rounded-full border border-bright-plum-50 bg-bright-plum-7 px-3 py-1 text-sm">
          <button className="shrink-0 text-bright-plum" type="button" onClick={playPause}>
            {isPlaying ? <PauseCircle weight="fill" size={32} /> : <PlayCircle weight="fill" size={32} />}
          </button>

          <div className="ml-1 h-full w-full" ref={containerRef} />

          <select
            className="shrink-0 cursor-pointer appearance-none border-transparent bg-transparent bg-none p-2 text-center outline-0 focus:border-0 focus:outline-0 focus:ring-0"
            onChange={(event) => changePlaybackSpeed(Number.parseFloat(event.target.value))}
          >
            <option value={1.0}>1x</option>
            <option value={1.25}>1.25x</option>
            <option value={1.5}>1.5x</option>
            <option value={1.75}>1.75x</option>
            <option value={2.0}>2x</option>
          </select>
        </div>
      )}
    </div>
  )
}

export default AudioBubble
